import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { ContentSubHeading, ContentHeading } from "../components/styles"
const slug = require("slug")

export default ({ pageContext: { teams } }) => (
  <Layout>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-6xl mx-auto mt-20">
        <h1>
          <ContentSubHeading>
            Pricelist use cases that make your team successful
          </ContentSubHeading>
          <ContentHeading>Help your team with Pricelist</ContentHeading>
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-16">
          {teams.map(({ name, description }) => (
            <Link
              className="text-base p-4 bg-gray-50 shadow-sm hover:shadow-lg transition duration-300 ease-in-out hover:bg-gradient-to-t hover:from-gray-50 hover:to-white"
              to={`/teams/${slug(name)}`}
            >
              <h3 className=" pb-4 font-semibold text-gray-900">{name}</h3>
              <p className="text-sm pb-4 text-gray-600">{description}</p>
              <p className="text-sm underline hover:no-underline text-indigo-700 hover:text-indigo-900">
                {name} use cases
              </p>
            </Link>
          ))}
        </div>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p></p>
      </div>
    </div>
  </Layout>
)
